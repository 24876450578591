var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _vm.label
      ? _c("label", { staticClass: "form-label" }, [_vm._v(_vm._s(_vm.label))])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "form-select cursor-pointer", class: _vm.style }, [
      _c(
        "div",
        { ref: "input", staticClass: "input-wrap", on: { click: _vm.open } },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name"
              }
            ],
            staticClass:
              "form-input rounded-left-0 rounded-right-0 border-right-0 events-disable",
            class: _vm.style,
            attrs: { type: "text", placeholder: _vm.placeholder },
            domProps: { value: _vm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) return
                _vm.name = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          !_vm.loading
            ? _c(
                "span",
                {
                  staticClass:
                    "border-btn bg-lighter border-left-0 rounded-left-0 p-0 events-disable",
                  class: _vm.btnStyle
                },
                [
                  _c("i", {
                    class: _vm.active ? "i-chevron-up" : "i-chevron-down"
                  })
                ]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", { ref: "dropdown", staticClass: "dropdown-wrap" }, [
        _c(
          "div",
          { staticClass: "dropdown", class: { active: _vm.active } },
          [
            _vm.filter
              ? _c("input", {
                  ref: "search",
                  staticClass: "form-input rounded-0 bg-white",
                  attrs: { type: "text", placeholder: "Search.." },
                  on: { keyup: _vm.onKeypress }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("perfect-scrollbar", { staticClass: "scroll-wrap" }, [
              _c(
                "nav",
                { staticClass: "nav flex-column" },
                _vm._l(_vm.options, function(option, i) {
                  return _c(
                    "span",
                    {
                      key: i,
                      staticClass: "btn",
                      class: { selected: _vm.selected === i },
                      on: {
                        click: function($event) {
                          return _vm.select(option)
                        },
                        mouseenter: function($event) {
                          _vm.selected = i
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(option.name) +
                          "\n            "
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _vm.help !== undefined
      ? _c("span", { staticClass: "form-help" }, [_vm._v(_vm._s(_vm.help))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }